<template>
  <div>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isShow"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-show', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-primary font-weight-bolder">
            {{ modalMode === 'create' ? 'Tạo nhân viên' : 'Thông tin nhân viên' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <div class="p-2">
          <dynamic-form
            :id="'product-form-phanquyen'"
            :value="user.role"
            :type="'select'"
            :label="'Phân quyền'"
            required="required"
            :select-default="false"
            :class="'form-group-hor'"
            :options="ROLES"
            @update-value="val => user.role = val"
          />

          <dynamic-form
            v-if="user.role === 'user' || user.role === 'manager_branch'"
            :id="'product-form-branch_id'"
            :value="user.branch_id"
            :type="'select'"
            :label="'Chi nhánh'"
            text-field="name"
            value-field="id"
            required="required"
            :select-default="false"
            :class="'form-group-hor'"
            :options="branches"
            @update-value="val => user.branch_id = val"
          />

          <dynamic-form
            :id="'user-form-email'"
            :type="'text'"
            :value="user.email"
            :label="'Email'"
            placeholder="Email"
            required="required email"
            :class="'form-group-hor'"
            @update-value="val => user.email = val"
          />

          <dynamic-form
            v-if="modalMode === 'create'"
            :id="'user-form-password'"
            :type="'text'"
            :value="user.password"
            :label="'Mật khẩu'"
            placeholder="Mật khẩu"
            required="required password"
            :class="'form-group-hor'"
            @update-value="val => user.password = val"
          />

          <dynamic-form
            :id="'user-form-hoten'"
            :value="user.fullname"
            :type="'text'"
            :label="'Họ tên'"
            placeholder="Họ tên"
            required="required"
            :class="'form-group-hor'"
            @update-value="val => user.fullname = val"
          />

          <dynamic-form
            :id="'user-form-phone'"
            :type="'text'"
            :value="user.phone"
            :label="'Số điện thoại'"
            placeholder="Số điện thoại"
            required="phone"
            :class="'form-group-hor'"
            @update-value="val => user.phone = val"
          />

          <div class="d-flex justify-content-between mb-3 mt-2">
            <span class="text-primary">Khóa tài khoản</span>
            <b-form-checkbox
              v-model="user.is_locked"
              class="text-warning justify-content-end"
              name="is-vertical-menu-collapsed"
              switch
              inline
            />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-between flex-wrap">
            <b-button
              v-if="modalMode === 'edit'"
              variant="outline-primary"
              class="mb-2 flex-grow-1 flex-sm-grow-0"
              @click="openModalChangePassword"
            >
              Đổi mật khẩu
            </b-button>

            <div class="d-flex justify-content-end align-items-start flex-grow-1 ml-2">
              <b-button
                v-if="modalMode === 'create'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                @click="$emit('submit', modalMode, user)"
              >
                Tạo
              </b-button>

              <b-button
                v-if="modalMode === 'edit'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                @click="$emit('submit', modalMode, user)"
              >
                Lưu
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Hủy
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
    <modal-change-password
      id="modal-admin-change-password"
      ref="modal-admin-change-password"
      modal-mode="admin-change-password"
      :user="user"
    />
  </div>
</template>

<script>
import { BButton, BFormCheckbox, BSidebar } from 'bootstrap-vue'
import { computed, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ModalChangePassword from '@/views/settings/users/list/ModalChangePassword.vue'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import store from '@/store'
import UserModal from '@/modal/userModal'

export default {
  components: {
    BSidebar,
    BButton,
    BFormCheckbox,
    ModalChangePassword,
    DynamicForm,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShow',
    event: 'update:is-show',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    modalMode: {
      type: String,
      default: null,
    },
    isShow: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    openModalChangePassword() {
      this.$refs['modal-admin-change-password'].$refs['btn-open-modal-change-password'].click()
    },
  },
  setup(props) {
    const user = UserModal()
    const roles = computed(() => store.state.app.roles)

    const resetForm = () => {
      Object.assign(user, UserModal())
    }

    const branches = computed(() => store.state.app.branches)

    watch(() => props.value, n => {
      if (!n) {
        resetForm()
      } else {
        Object.assign(user, n)
      }
    }, { immediate: true })

    return {
      user,
      roles,
      branches,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
