import { ref, reactive } from '@vue/composition-api'

const UserModal = () => reactive({
  id: ref(''),
  email: ref(''),
  fullname: ref(''),
  phone: ref(''),
  date_of_birth: ref(null),
  username: `username default ${new Date().getTime().toString()}`,
  password: ref(''),
  // is_limit_price: ref(false),
  limit_price: ref(null),
  avatar: ref(''),
  gender: ref(''),
  role: ref(''),
  branch_id: ref(null),
  is_locked: ref(null),
  is_access_townhouse: ref(false),
  is_access_office: ref(false),
  is_limit_price: ref(true),
  is_limit_price_office: ref(false),
  limit_office_price: ref(0),
  is_limit_townhouse_price_show_phone: ref(false),
  is_limit_townhouse_price_show_product: ref(false),
  limit_townhouse_hire_price_show_phone: ref(0),
  limit_townhouse_sell_price_show_phone: ref(0),
  limit_townhouse_hire_price_show_product: ref(0),
  limit_townhouse_sell_price_show_product: ref(0),

  is_limit_townhouse_price_show_phone_hire: ref(0),
  is_limit_townhouse_price_show_product_hire: ref(0),
  is_limit_townhouse_price_show_phone_sell: ref(0),
  is_limit_townhouse_price_show_product_sell: ref(0),

  is_access_townhouse_hire: ref(0),
  is_access_townhouse_sell: ref(0),

  is_access_hotel: ref(0),
  is_limit_hotel_price_show_phone: ref(0),
  limit_hotel_price_show_phone: ref(0),
  is_limit_hotel_price_show_product: ref(0),
  limit_hotel_price_show_product: ref(0),
})

export default UserModal
