import store from '@/store'

const TEXT_UNAUTHORIZED = 'Cần đăng nhập trước!'

const http = {
  getISODate(d) {
    try {
      const r = new Date(d)
      return r.toISOString()
    } catch (e) {
      return d
    }
  },
  handle(storeDispatch, cb) {
    return storeDispatch
      .then(res => {
        const data = res.data || {}
        if (cb) cb(data)
        return data
      })
      .catch(err => {
        const resp = err.response || {}
        if (resp.status === 500 && resp.statusText === 'Internal Server Error' && (resp.data?.message === 'Unauthorized' || resp.data?.message === 'verify jwt error: token is revoked')) {
          store.commit('app/onTriggerLogout')
          throw TEXT_UNAUTHORIZED
        }
        let eMsg = err?.response?.data?.message
        eMsg = eMsg || err?.response?.data?.error?.message || err
        throw eMsg
      })
  },
  buildQuery(params) {
    if (!params) {
      return ''
    }
    if (typeof params === 'string') {
      return params
    }
    if (typeof params !== 'object') {
      return ''
    }
    const arr = []
    const keys = Object.keys(params)
    for (let i = 0; i < keys.length; i += 1) {
      if ((params[keys[i]] || params[keys[i]] === 0)
          && !(Array.isArray(params[keys[i]]) && params[keys[i]].length === 0)
      ) arr.push(`${encodeURIComponent(keys[i])}=${params[keys[i]] === undefined ? '' : encodeURIComponent(params[keys[i]])}`)
    }
    return `?${arr.join('&')}`
  },
}

export default http
